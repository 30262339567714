import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axiosRetry from "axios-retry";
import { getAccessToken, getRefreshToken, setTokens } from "../auth/AuthRoutes";
import BlindlyGroupsApi from '../http/config';

const validateStatus = (status) =>
  (status >= 200 && status < 300) ||
  (status > 400 && status !== 404 && status < 500);

export const authRefresh = async () => {
  try {
    const oldRefreshToken = await getRefreshToken();
    const res = await axios.post(
      //Will change root to be dynamic later
      `${BlindlyGroupsApi.auth}/refresh`,
      {
        oldRefreshToken,
      }
    );
    const { accessToken, refreshToken } = res.data;
    await setTokens(accessToken, refreshToken);
    return accessToken;
  } catch (err) {
    console.error("Could not refresh access token", err);
  }
};

const customAxiosConfig = {
  timeout: 20000,
  validateStatus,
};

export const getSecureAxios = async () => {
  const accessToken = await getAccessToken();
  const customAxios = axios.create({
    ...customAxiosConfig,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  axiosRetry(customAxios, {
    retries: 10,
  });

  createAuthRefreshInterceptor(
    customAxios,
    async () => {
      await authRefresh();
    },
    {
      statusCodes: [401, 403],
    }
  );

  return customAxios;
};

import { combineReducers } from "redux";
import socketReducer, {
  initialState as socketInitialState,
} from "./socket/socket";
import userReducer, {
  initialState as userSliceInitialState,
} from "./slices/userSlice";
import promise from "redux-promise-middleware";
import namespaceReducer, {
  initialState as namespaceSliceInitialState,
} from "./slices/namespaceSlice";
import { createLogger } from "redux-logger";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import socketMiddleware from "./socket/socketMiddleware";

const rootReducer = combineReducers({
  socket: socketReducer,
  user: userReducer,
  namespace: namespaceReducer,
});

export const RootInitialState = {
  socket: socketInitialState,
  user: userSliceInitialState,
  namespace: namespaceSliceInitialState,
};

export const CLEAR = "CLEAR";

export const clearStore = () => ({ type: CLEAR });

export const rootReducerWithClearAction = (state, action) => {
  const clearState = {
    ...RootInitialState,
  };
  return rootReducer(action.type === CLEAR ? clearState : state, action);
};

const store = configureStore({
  reducer: rootReducerWithClearAction,
  middleware: getDefaultMiddleware().concat([
    promise,
    socketMiddleware(),
    // createLogger({
    //   level: {
    //     prevState: false,
    //     nextState: false,
    //     action: "info",
    //     error: "error",
    //   },
    //   diff: true,
    // }),
  ]),
});

export default store;

import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Paragraph,
  TextInput,
  Button,
  FAB,
  Appbar,
  useTheme,
  Modal,
  Portal,
  Headline,
} from "react-native-paper";
import { View, StyleSheet, FlatList } from "react-native";
import { useUser } from "../Context/UserContext";
// import { useTheme } from "@react-navigation/native";
import { sendMessage, sendRoomInvite } from "../state/socket/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  findRoomById,
  selectUserData,
  selectUserName,
} from "../state/selectors";

export default function ChannelChatScreen(props) {
  const [openForm, setOpenForm] = useState(false);
  const [lockedRoom, setLockedRoom] = useState(false);
  const flatListRef = useRef();
  const paperTheme = useTheme();
  const [message, setMessage] = useState("");
  const [userToInvite, setUserToInvite] = useState("");
  const [userList, setUserList] = useState([]);
  const { roomID } = props.route.params;
  const user = useSelector(selectUserData);
  const roomData = useSelector(findRoomById(roomID));
  const username = useSelector(selectUserName);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.route.params.locked === true) setLockedRoom(true);
    else setLockedRoom(false);
  }, [props.route]);

  const handleSendMessage = () => {
    const toBroad = {
      message,
      roomID,
      date: Date.now(),
    };
    dispatch(sendMessage(toBroad));
    setMessage("");
  };
  const handleAddUserToInvite = (user) => {
    setUserList((prev) => [...prev, user]);
    setUserToInvite("");
  };

  const handleSubmitUsers = () => {
    const data = {
      usernames: userList,
      name: username,
      roomID: roomID,
    };
    dispatch(sendRoomInvite(data));
    handleDismissModal();
  };

  const handleDismissModal = () => {
    setUserToInvite("");
    setUserList("");
    setOpenForm(false);
  };

  return (
    <View
      style={{
        ...styles.container,
        backgroundColor: "#ccd4e3",
      }}
    >
      <Portal>
        <Modal
          visible={openForm}
          onDismiss={() => handleDismissModal()}
          contentContainerStyle={{ padding: 10, backgroundColor: "white" }}
        >
          <Headline style={{ padding: 10, margin: 5 }}>
            Invite member to group
          </Headline>
          <TextInput
            testID="addMemberToChatInput"
            value={userToInvite}
            onChangeText={(t) => setUserToInvite(t)}
            style={{ margin: 10 }}
            autoCapitalize={"none"}
          />
          <Paragraph>Currently Inviting: </Paragraph>
          <FlatList
            data={userList}
            keyExtractor={(_, index) => index.toString()}
            renderItem={({ item }) => {
              return <Paragraph>{item}</Paragraph>;
            }}
          />
          <Button testID="addMemberToChatBtn" onPress={() => handleAddUserToInvite(userToInvite)}>
            Add User To Invite List
          </Button>
          <Button testID="addMembersToChatBtn" mode="contained" onPress={() => handleSubmitUsers()}>
            Invite Users
          </Button>
        </Modal>
      </Portal>
      {(roomData || roomID === 0) && (
        <Appbar.Header style={{ backgroundColor: paperTheme.background }}>
          <Appbar.Action
            icon="dots-vertical"
            onPress={() => props.navigation.toggleDrawer()}
          />
          <Appbar.Content
            title={roomData ? roomData.name : props.route.params.roomName}
            // subtitle={`Members online: ${membersOnline}`}
            style={{ marginLeft: 10 }}
          />
          {!lockedRoom && (
            <FAB
              testID="addToChatBtn"
              style={styles.fab}
              small
              icon="plus"
              color="black"
              onPress={() => setOpenForm(true)}
              animated={true}
            />
          )}
        </Appbar.Header>
      )}
      {!lockedRoom && roomData && roomData.messages.length > 0 && (
        <View style={styles.chatBox}>
          <>
            <FlatList
              ref={flatListRef}
              initialNumToRender={100}
              onContentSizeChange={() =>
                flatListRef.current.scrollToEnd({ animated: true })
              }
              onLayout={() =>
                flatListRef.current.scrollToEnd({ animated: true })
              }
              data={roomData.messages}
              keyExtractor={(item, index) => item.guid + index}
              renderItem={({ item }) => {
                let align = item.guid === user.guid ? "flex-end" : "flex-start";
                return (
                  <Card
                    key={(item, index) => index + item.guid}
                    style={{ margin: 10 }}
                  >
                    <Card.Content>
                      <Paragraph style={{ alignSelf: align }}>
                        {item.message}
                      </Paragraph>
                    </Card.Content>
                  </Card>
                );
              }}
            />
          </>
        </View>
      )}
      {!lockedRoom && (
        <View style={styles.chatcontainer}>
          <Button testID="sendMessageBtn" style={styles.button} onPress={() => handleSendMessage()}>
            Send
          </Button>

          <TextInput
            testID="sendMessageInput"
            style={styles.inputBar}
            title="chat"
            value={message}
            onChangeText={(t) => setMessage(t)}
          />
        </View>
      )}
      {lockedRoom && (
        <View style={styles.lockedContainer}>
          <Paragraph>
            This room is locked to some users, ask someone to invite you to gain
            access.
          </Paragraph>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  chatcontainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "column",
  },
  inputBar: {
    margin: 30,
    height: 100,
    width: "90%",
    backgroundColor: "white",
  },
  button: {
    alignSelf: "flex-end",
    color: "red",
  },
  chatBox: {
    flex: 3,
    margin: 20,
  },
  fab: {
    margin: 16,
    backgroundColor: "orange",
  },
  lockedContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: 20,
  },
});

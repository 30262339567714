import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import {
  loginUser,
  registerUser,
  logOut,
} from "../../services/http/blindlyGroups/AuthDao";
import { getUserMembership } from "../../services/http/chat/getUserMembership";
import {
  getRefreshToken,
  setTokens,
  clearTokens,
} from "../../services/auth/AuthRoutes";
import { getUserProfile } from "../../services/http/profile/getUserProfile";
import { getNamespaceNames } from "../../services/http/chat/getNamespaceNames";
export const initialState = {
  user: undefined,
  membership: undefined,
  statusMap: {
    login: "idle",
    onLogin: "idle",
    register: "idle",
    logout: "idle",
  },
  errorMap: {
    login: undefined,
    onLogin: undefined,
    register: undefined,
  },
};

export const loginQuarium = createAsyncThunk(
  "user/loginQuarium",
  async ({ email, password }, thunkApi) => {
    try {
      const response = await loginUser(email, password);

      const accessToken = response.data.accessToken;
      const refreshToken = response.data.refreshToken;

      if (!accessToken || !refreshToken) {
        thunkApi.rejectWithValue({
          type: "AUTH_FAILED",
          reason: "No token returned from server ",
        });
      }

      await setTokens(accessToken, refreshToken);

      const loginResult = await thunkApi.dispatch(onLogin({ accessToken }));

      if (loginResult.rejected.match(loginResult)) {
        return thunkApi.rejectWithValue(loginResult.payload);
      }
      return loginResult.payload;
    } catch (err) {
      thunkApi.rejectWithValue({
        type: "LOGIN_QUARIUM_ERROR",
        reason: "Could not login",
      });
    }
  }
);

export const onLogin = createAsyncThunk(
  "user/onLogin",
  async ({ accessToken }, thunkApi) => {
    const user = jwt_decode(accessToken);
    const userName = await getUserProfile(user.guid);
    const membership = await getUserMembership(user);
    const namespaceNames = [];
    for (let i = 0; i < membership.nsps.length; i++) {
      const name = await getNamespaceNames(membership.nsps[i]);
      namespaceNames.push({ name: name.nspName, id: membership.nsps[i] });
    }
    membership.nsps = namespaceNames;
    return { membership, user, userName };
  }
);

export const registerLocalUser = createAsyncThunk(
  "user/registerLocalUser",
  async ({ email, password }, thunkApi) => {
    try {
      const response = await registerUser(email, password);

      const { accessToken, refreshToken } = response.data;

      if (!accessToken || !refreshToken) {
        return thunkApi.rejectWithValue({
          type: "ACCOUNT CREATION FAILED",
          reason: "Failed to create a user account",
        });
      }

      await setTokens(accessToken, refreshToken);

      const loginResult = await thunkApi.dispatch(onLogin(accessToken));

      if (loginResult.rejected.match(loginResult)) {
        return thunkApi.rejectWithValue(loginResult.payload);
      }

      return loginResult.payload;
    } catch (err) {
      thunkApi.rejectWithValue({
        type: "REGISTRATION_ERROR",
        reason: "failed to register local user ",
      });
    }
  }
);

export const signOut = createAsyncThunk("user/signOut", async (_, thunkApi) => {
  const refreshToken = await getRefreshToken();

  if (!refreshToken) {
    return thunkApi.rejectWithValue({
      type: "AUTH_FAILED",
      reason: "Invalid refresh token",
    });
  }

  await Promise.all([logOut(refreshToken), clearTokens()]);
  thunkApi.dispatch({ type: "CLEAR" });
  return true;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginQuarium.pending, (state) => {
        state.statusMap.login = "loading";
      })
      .addCase(loginQuarium.fulfilled, (state, action) => {
        state.statusMap.login = "succeeded";
        // state.user = action.payload.user;
      })
      .addCase(loginQuarium.rejected, (state, action) => {
        state.statusMap.login = "failed";
        state.errorMap.login = action.error.message;
      })
      .addCase(onLogin.pending, (state) => {
        state.statusMap.onLogin = "loading";
      })
      .addCase(onLogin.fulfilled, (state, action) => {
        state.statusMap.onLogin = "succeeded";
        state.membership = action.payload.membership;
        state.user = action.payload.user;
        state.userName = action.payload.userName.username;
      })
      .addCase(onLogin.rejected, (state, action) => {
        state.statusMap.onLogin = "failed";
        state.errorMap.onLogin = action.error.message;
      })
      .addCase(registerLocalUser.pending, (state) => {
        state.statusMap.register = "loading";
      })
      .addCase(registerLocalUser.fulfilled, (state, action) => {
        state.statusMap.register = "succeeded";
        state.user = action.payload;
      })
      .addCase(registerLocalUser.rejected, (state, action) => {
        state.statusMap.register = "failed";
        state.errorMap.register = action.error.message;
      })
      .addCase(signOut.pending, (state) => {
        state.statusMap.logout = "loading";
      })
      .addCase(signOut.fulfilled, (state) => {
        state.statusMap.logout = "succeeded";
      })
      .addCase(signOut.rejected, (state, action) => {
        state.statusMap.logout = "failed";
        state.error = action.payload?.type ?? action.error.message;
      });
  },
});

const { reducer, actions } = userSlice;
export default reducer;

import { getSecureAxios } from "../axios";
import BlindlyGroupsApi from "../config";

const SERVICE = BlindlyGroupsApi.chat;

export async function getNamespaceNames(nspID) {
  const namespaceName = new Promise((resolve, reject) =>
    getSecureAxios()
      .then((axios) => axios.post(`${SERVICE}/nsp/get_name`, { nspID: nspID }))
      .then((res) => resolve(res.data))
      .catch((err) => {
        console.error("Error Getting namespace name", err);
        reject(err);
      })
  );
  return namespaceName;
}

import React from "react";
import { ScrollView, StyleSheet, SafeAreaView, View, Text } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useTheme, Appbar, FAB, Paragraph } from "react-native-paper";
import { useActionSheet } from "@expo/react-native-action-sheet";
import Center from "../components/Prebuilt/Center";
import { useStore } from "../Context/StoreContext";
import testUsers from "../../data/testUsers";

export default function ChatScreen(props) {
  const [storeState, storeAction] = useStore();
  const paperTheme = useTheme();
  const { showActionSheetWithOptions } = useActionSheet();

  // React.useEffect(() => {
  //   const theTimeout = setInterval(() => {
  //     storeAction.updateMembersOnline(
  //       Math.floor(Math.random() * Math.floor(17000))
  //     );
  //   }, 11000);
  //   return () => clearInterval(theTimeout);
  // }, []);

  const membersOnline = storeState.membersOnline;

  const openActionSheet = () => {
    // Same interface as https://facebook.github.io/react-native/docs/actionsheetios.html
    const options = ["Jump To Chat", "Cancel", "Block Member"];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        // Do something here depending on the button index selected
        console.log(buttonIndex);
        switch (buttonIndex) {
          case 2:
            console.log("Umm . . . tag you're it!");
        }
      }
    );
  };
  console.log("re rendering chat");

  return (
    <View style={{ flex: 1 }}>
      <Appbar.Header style={{ backgroundColor: paperTheme.background }}>
        <Appbar.Action
          testID="sideBarBtn"
          icon="dots-vertical"
          onPress={() => props.navigation.toggleDrawer()}
        />
        <Appbar.Content
          title="BlindlyGroup"
          subtitle={`Members online: ${membersOnline}`}
          style={{ marginLeft: 10 }}
        />
        <FAB
          testID="addChatBtn"
          style={styles.fab}
          small
          icon="plus"
          color="black"
          onPress={openActionSheet}
          animated={true}
        />
      </Appbar.Header>

      <SafeAreaView style={{ flex: 1 }}>
        <ScrollView>
          <View style={{ alignItems: "center" }}>
            <Text
              style={{
                fontWeight: "700",
                marginTop: 10,
                color: paperTheme.dark ? "#fff" : "#000",
              }}
            >
              {props.route.name}
            </Text>
            <Text style={{ color: paperTheme.dark ? "#fff" : "#000" }}>
              Dark Mode: {paperTheme.dark ? "On" : "Off"}
            </Text>
            {/* <Text style={{ color: paperTheme.dark ? '#fff' : '#000' }}>{JSON.stringify(props, null, 2)}</Text> */}
          </View>
          <Center>
            <Paragraph style={{ color: paperTheme.dark ? "#fff" : "#000" }}>
              Swipe the left side of the screen to view Drawer
            </Paragraph>
          </Center>
        </ScrollView>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  fab: {
    margin: 16,
    backgroundColor: "orange",
  },
});

import React, { useEffect, useState } from "react";
import { SafeAreaView, StyleSheet, View, Image } from "react-native";
import {
  Card,
  Headline,
  useTheme,
  Button,
  TextInput,
  Paragraph,
  Divider,
  List,
} from "react-native-paper";
import { FlatList, ScrollView } from "react-native-gesture-handler";
import { useSelector } from "react-redux";
import {
  selectUserData,
  selectUserMembership,
  selectNspIds,
  selectNspNames,
} from "../state/selectors";
import { signOut } from "../state/slices/userSlice";
import { useDispatch } from "react-redux";
import { setNamespace, createNamespace } from "../state/slices/namespaceSlice";
import { openSocket } from "../state/socket/socket";
import { getAccessToken } from "../services/auth/AuthRoutes";
import BlindlyGroupsApi from "../services/http/config";
import Icon from "../Assets/demoIcon.png";

export default function ChooseNamespace(props) {
  const [loading, setLoading] = useState(true);
  const [userMemberShip, setUserMembership] = useState([]);
  const [invites, setInvites] = useState([]);
  const [name, setName] = useState();
  const [nsps, setNsps] = useState([]);
  const dispatch = useDispatch();
  const membership = useSelector(selectUserMembership);
  const user = useSelector(selectUserData);

  const paperTheme = useTheme();

  useEffect(() => {
    if (membership) {
      setInvites(membership.invites.filter((i) => i.inviteType === "nsp"));
      setNsps(membership.nsps);
      setLoading(false);
    }
  }, []);

  const handleCreateNamespace = async () => {
    dispatch(createNamespace({ name }));
    // const nsp = await createNewNsp(name);
    // storeAction.setUserNamespace(nsp.nsp);
  };

  const handleSetNamespace = async (namespace) => {
    const accessToken = await getAccessToken();
    const devUrl = `http://192.168.0.101:4040`;
    dispatch(openSocket(BlindlyGroupsApi.socket, namespace, accessToken));
  };

  const handleLogOut = () => {
    dispatch(signOut());
  };

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: paperTheme.colors.background }}
    >
      <ScrollView>
        <Button
          style={{ alignSelf: "flex-start" }}
          onPress={() => handleLogOut()}
        >
          Logout
        </Button>
        <Image width={50} height={50} style={styles.logo} source={Icon} />
        {/*  TO  render if there is invited*/}
        {membership && invites.length > 0 && (
          <View style={styles.cardContainer}>
            <Headline>Invites</Headline>
            <List.Section>
              {invites.map((inv) => {
                return (
                  <>
                    <Button
                      style={{
                        ...styles.buttonContent,
                        backgroundColor: paperTheme.colors.accent,
                      }}
                      mode="contained"
                      onPress={() => handleSetNamespace(inv.inviteID)}
                      icon="chevron-right"
                      key={inv}
                    >
                      {inv.inviteID}
                    </Button>
                  </>
                );
              })}
            </List.Section>
          </View>
        )}
        {/* to render if there is no namespaces to enter */}
        {userMemberShip && nsps.length === 0 && (
          <>
            <Headline style={styles.header}>
              Create a Namespace to get started
            </Headline>

            <TextInput
              label="ex... 'Office Chat'"
              testID="AdditionalNamespaceInput"
              value={name}
              onChangeText={(t) => setName(t)}
              style={styles.textInput}
            />
            <Button
              style={{
                ...styles.buttonContent,
                backgroundColor: paperTheme.colors.primary,
              }}
              testID="AddNewNamespaceBtn"
              mode="contained"
              onPress={() => handleCreateNamespace()}
            >
              Submit
            </Button>
          </>
        )}
        {/* to render if there is namespaces to enter  */}
        {userMemberShip && nsps.length > 0 && (
          <>
            <View style={styles.cardContainer}>
              <Headline style={{ ...styles.header }}>
                Select a Namespace to Enter
              </Headline>
              <ScrollView>
                <List.Section>
                  {nsps.map((nsp) => {
                    return (
                      <>
                        <Button
                          style={{
                            ...styles.buttonContent,
                            backgroundColor: paperTheme.colors.accent,
                          }}
                          mode="contained"
                          onPress={() => handleSetNamespace(nsp.id)}
                          icon="chevron-right"
                          key={nsp.id}
                        >
                          {nsp.name}
                        </Button>
                      </>
                    );
                  })}
                </List.Section>
              </ScrollView>

              <Divider />
              <Headline style={{ ...styles.header, opacity: 0.7 }}>
                Or Create A New Namespace
              </Headline>

              <TextInput
                label="ex... 'Office Chat'"
                testID="AdditionalNamespaceInput"
                value={name}
                onChangeText={(t) => setName(t)}
                style={styles.textInput}
              />
              <Button
                style={{
                  ...styles.buttonContent,
                  backgroundColor: paperTheme.colors.primary,
                }}
                testID="AddNewNamespaceBtn"
                mode="contained"
                onPress={() => handleCreateNamespace()}
              >
                Submit
              </Button>
            </View>
          </>
        )}
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  cardContainer: {
    margin: 10,
    padding: 10,
    borderRadius: 20,
  },
  header: {
    color: "black",
    // alignSelf: "center",
    padding: 20,
  },
  button: {
    color: "black",
    margin: 2,
  },
  buttonContent: {
    padding: 10,
    margin: 10,

    opacity: 0.9,
  },
  logo: {
    // margin: 10,
    justifyContent: "center",
    alignSelf: "center",
  },
  textInput: {
    margin: 10,
  },
});

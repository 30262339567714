import React, { useContext } from "react";
import { StyleSheet, ActivityIndicator } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { Modal, Text } from "react-native-paper";
import { createStackNavigator } from "@react-navigation/stack";
import {
  createDrawerNavigator,
  DrawerItem,
  DrawerContentScrollView,
} from "@react-navigation/drawer";
import { AntDesign } from "@expo/vector-icons";
import AppTabs from "./components/Navigation/AppTabs";
import SignUpScreen from "./screens/SignUp";
import LoginScreen from "./screens/Login";
import { useStore } from "./Context/StoreContext";
import Center from "./components/Prebuilt/Center";
import AppDrawerContent from "./components/Navigation/DrawerContent";
import ChatScreen from "./screens/ChatScreen";
import ProfileScreen from "./screens/ProfileScreen";
import DMScreen from "./screens/DMScreen";
import ThreadsScreen from "./screens/Threads";
import SettingsScreen from "./screens/Settings";
import SupportScreen from "./screens/Support";
import ChooseNamespace from "./screens/ChooseNamespace";
import CreateChannelForm from "./screens/CreateChannelForm";
import ChannelChatScreen from "./screens/ChannelChatScreen";
import { useSelector, useDispatch } from "react-redux";
import {
  selectIsSignedId,
  selectState,
  selectCurrentNamespace,
  selectIsConnected,
  selectNewRoomInvites,
  selectCurrentNamespaceName,
  selectIsAppLoading,
} from "./state/selectors";
import { disconnectFromSocket, loginToNamespace } from "./state/socket/socket";
import { getUser } from "./components/Util/getUser";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

const AuthStack = createStackNavigator();
const AppStack = createStackNavigator();
const AppDrawer = createDrawerNavigator();
const Tab = createBottomTabNavigator();

function App() {
  const isSignedIn = useSelector(selectIsSignedId);
  const state = useSelector(selectState);
  const namespace = useSelector(selectCurrentNamespace);
  const namespaceName = useSelector(selectCurrentNamespaceName);
  const isAppLoading = useSelector(selectIsAppLoading);
  const isConnected = useSelector(selectIsConnected);
  const newRoomInvites = useSelector(selectNewRoomInvites);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  // hack to log the state nicely in the terminal
  // console.info("######state :", JSON.stringify(state, null, "\t"));

  React.useEffect(() => {
    if (isAppLoading === "loading") setLoading(true);
    else if (isAppLoading === "succeeded") setLoading(false);
  }, [isAppLoading]);

  React.useEffect(() => {
    return () => disconnectFromSocket();
  });

  React.useEffect(() => {
    getUser().then((user) => {
      dispatch(loginToNamespace(user));
    });
  }, [isConnected]);

  const AuthNavigator = () => (
    <AuthStack.Navigator>
      <AuthStack.Screen
        name="Sign In"
        component={LoginScreen}
        options={{ headerShown: false }}
      />
      <AuthStack.Screen name="Sign Up" component={SignUpScreen} />
    </AuthStack.Navigator>
  );

  // const Home = () => {
  //   return (
  //     <AppStack.Navigator>
  //       <AppStack.Screen name={namespaceName} component={AppDrawerContent} />
  //       <AppStack.Screen name="ChannelChatScreen" component={ChannelChatScreen} />
  //       <AppStack.Screen name="CreateChannelForm" component={CreateChannelForm} />
  //     </AppStack.Navigator>
  //   )
  // }

  // const TabNavigator = () => {
  //   if (isConnected)
  //     return (
  //       <Tab.Navigator>
  //         <Tab.Screen name="Home" component={Home} />
  //         <Tab.Screen name="Chat" component={ChatScreen} />
  //       </Tab.Navigator>
  //     )
  // }

  const AppDrawerNavigator = () => {
    if (isConnected)
      return (
        <AppDrawer.Navigator
          drawerContent={(props) => <AppDrawerContent {...props} />}
          drawerContentOptions={{
            activeTintColor: "blue",
            labelStyle: {
              color: "white",
            },
          }}
        >
          <AppDrawer.Screen
            name="Chat"
            children={(props) => <ChatScreen name="Blindly Chat" {...props} />}
          />
          <AppDrawer.Screen
            name="Profile"
            children={(props) => <ProfileScreen name="Profile" {...props} />}
          />
          <AppDrawer.Screen
            name="DirectMessages"
            children={(props) => <DMScreen {...props} />}
          />
          <AppDrawer.Screen
            name="Threads"
            children={(props) => <ThreadsScreen {...props} />}
          />
          <AppDrawer.Screen
            name="Settings"
            children={(props) => <SettingsScreen {...props} />}
          />
          <AppDrawer.Screen
            name="CreateChannelForm"
            children={(props) => <CreateChannelForm {...props} />}
          />
          <AppDrawer.Screen
            name="ChannelChatScreen"
            children={(props) => <ChannelChatScreen {...props} />}
          />
          <AppDrawer.Screen
            name="Support"
            children={(props) => <SupportScreen {...props} />}
          />
        </AppDrawer.Navigator>
      );
  };

  if (loading)
    return (
      <Center>
        <Text style={styles.welcomeText}>👋</Text>
        <ActivityIndicator />
      </Center>
    );

  if (!namespace && isSignedIn) {
    return <ChooseNamespace />;
  }

  return <>{!isSignedIn ? <AuthNavigator /> : <AppDrawerNavigator />}</>;
}

const styles = StyleSheet.create({
  welcomeText: {
    marginBottom: 5,
    fontSize: 36,
  },
});

export default App;

import io from "socket.io-client";
import {
  socketConnectionSuccess,
  setOnlineMembers,
  setUserInvites,
  setNewRoom,
  setNewMessage,
  fetchAllRoomData,
  fetchRoomData,
  setReceivedNewInvite,
  setAcceptInvite,
} from "./socket";
/*

 * Socket midleware to encapsulate emitting and subscribing to and from the server
 * Actions should follow the syntax of
 *      function newAction(data) {
 *          return {
 *            type: "CASE_EVENT",
 *            payload: data
 *          }
 *      }
 */

function createWebSocket(url, namespace, token) {
  const URL = `${url}/${namespace}`;
  const auth = {
    token: `Bearer ${token}`,
  };
  const socket = io(URL, {
    transports: ["websocket"],
    auth,
    // forceNew: true,
  });
  return socket;
}

export default function socketMiddleware() {
  let socket;
  return (storeAPI) => (next) => (action) => {
    switch (action.type) {
      case "OPEN_SOCKET": {
        const { url, namespace, token } = action.payload;
        if (socket && socket.connected) socket.disconnect();
        socket = createWebSocket(url, namespace, token);

        socket.on("connect", () =>
          storeAPI.dispatch(socketConnectionSuccess(namespace))
        );

        socket.on("login", (onlineMembers) =>
          storeAPI.dispatch(setOnlineMembers(onlineMembers))
        );

        socket.on("nspData", (nspData) => {
          storeAPI.dispatch(fetchAllRoomData({ nspData }));
        });

        socket.on("inviteSuccess", (room) => {
          console.log("##INVITESUCCESS", room);
          return storeAPI.dispatch(setNewRoom(room));
        });

        socket.on("inviteNspSuccess", () =>
          console.log("succesfully Invited to Nsp")
        );

        socket.on("receiveInvite", (inv) => {
          const roomID = inv.inviteID ? inv.inviteID : inv.roomID;
          return storeAPI.dispatch(fetchRoomData({ roomID, invite: true }));
        });

        socket.on("newMessage", (message) => {
          return storeAPI.dispatch(setNewMessage(message));
        });

        break;
      }

      case "SEND_MESSAGE": {
        console.log("SENDING MESSAGE ");
        const { message, roomID, date } = action.payload;
        socket.emit("newMessage", { message, roomID, date });
        return;
      }
      case "SEND_ROOM_INVITE": {
        socket.emit("sendInvite", action.payload);
        return;
      }
      case "CREATE_NEW_ROOM": {
        socket.emit("sendInvite", action.payload);
        return;
      }
      case "SEND_NSP_INVITE": {
        socket.emit("sendNspInvite", action.payload);
        return;
      }
      case "LOGIN": {
        socket.emit("login", action.payload);
        return;
      }
      case "ACCEPT_INVITE": {
        socket.emit("acceptInvite", { roomID: action.payload.roomID });
        return storeAPI.dispatch(
          setAcceptInvite({ roomID: action.payload, user: action.payload.user })
        );
      }
      case "DISCONNECT": {
        socket.emit("disconnect");
        socket.disconnect();
        return;
      }
    }
    return next(action);
  };
}

const BlindlyGroups = require("../../../package.json").api;

const BlindlyGroupsApi = {
  main: BlindlyGroups,
  auth: `https://auth.quariumgroups.com/api/auth`,
  chat: `https://chat.quariumgroups.com/api/chat`,
  profile: `https://profile.quariumgroups.com/api/profile`,
  socket: `https://chat.quariumgroups.com`,
  dev: `${`http://192.168.0.101:4040`}`,
};

export default BlindlyGroupsApi;

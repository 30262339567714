import { getUser } from "../../../components/Util/getUser";
import { getSecureAxios } from "../axios";
import BlindlyGroupsApi from "../config";
import profileRoutes from "./routes";

const SERVICE = BlindlyGroupsApi.profile;

export async function getUserProfile() {
  const user = await getUser();
  const { guid } = user;
  return new Promise((resolve, reject) =>
    getSecureAxios()
      .then((axios) =>
        axios.post(`${SERVICE}/get_username`, {
          guid,
        })
      )
      .then((res) => resolve(res.data))
      .catch((err) => {
        console.error("Error getting Profile", err);
        reject(err);
      })
  );
}

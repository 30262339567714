import { createAsyncThunk, createSlice, isAllOf } from "@reduxjs/toolkit";
import { getUser } from "../../components/Util/getUser";
import { getChatRoomData } from "../../services/http/chat/getChatRoomData";

export const initialState = {
  connected: false,
  nspData: [],
  onlineMembers: [],
  namespace: undefined,
  invites: [],
  statusMap: {
    fetchingRoomData: "idle",
    fetchingAllRoomData: "idle",
  },
  errorMap: {
    fetchingRoomData: undefined,
    fetchingAllRoomData: undefined,
  },
};

// actions for socketMiddleware

export function sendMessage(message) {
  console.log("DISPATCHING MESSAGE");
  return {
    type: "SEND_MESSAGE",
    payload: message,
  };
}

export function sendRoomInvite(data) {
  console.log("###DATA", data);
  return {
    type: "SEND_ROOM_INVITE",
    payload: data,
  };
}

export function createNewRoom(room) {
  return {
    type: "CREATE_NEW_ROOM",
    payload: room,
  };
}

export function sendNspInvite(data) {
  return {
    type: "SEND_NSP_INVITE",
    payload: data,
  };
}

export function openSocket(url, namespace, token) {
  return {
    type: "OPEN_SOCKET",
    payload: { url: url, namespace: namespace, token: token },
  };
}

export function loginToNamespace(userGuid) {
  return {
    type: "LOGIN",
    payload: userGuid,
  };
}

export function acceptInvite(roomID, user) {
  return {
    type: "ACCEPT_INVITE",
    payload: { roomID, user },
  };
}

export function disconnectFromSocket() {
  return {
    type: "DISCONNECT",
  };
}

export const fetchAllRoomData = createAsyncThunk(
  "socket/fetchAllRoomData",
  async ({ nspData }, thunkApi) => {
    if (!nspData) {
      return;
    }

    let rooms = [];
    const incRooms = nspData.roomIDs.map(async (r) => {
      const data = await getChatRoomData(r);
      if (data.error) return;
      rooms.push(data.room);
      return data.room;
    });
    await Promise.all(incRooms);

    nspData.roomsData = rooms;
    return nspData;
  }
);

export const fetchRoomData = createAsyncThunk(
  "socket/fetchRoomData",
  async ({ roomID, invite }, thunkApi) => {
    if (!roomID) return;
    if (invite) thunkApi.dispatch(setReceivedNewInvite(roomID));
    const data = await getChatRoomData(roomID);
    if (data.error) return;

    // thunkApi.dispatch(setNewRoom({ room: data.room }));
    return data.room;
  }
);

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    socketConnectionSuccess(state, action) {
      state.connected = true;
      state.namespace = action.payload;
    },
    setOnlineMembers(state, action) {
      state.onlineMembers = action.payload;
    },
    setNspData(state, action) {
      state.nspData = action.payload;
    },
    setNewRoom(state, action) {
      if (
        state.nspData.roomsData.find((r) => r._id === action.payload.room._id)
      )
        return;
      console.log("SETTTING THE ROOM ", action.payload.room._id);
      state.nspData.roomsData.push(action.payload.room);
      state.nspData.roomIDs.push(action.payload.room._id);
    },
    setReceivedNewInvite(state, action) {
      state.invites.push(action.payload);
    },
    removeInvite(state, action) {
      const removeIndex = state.membership.invites
        .map((item) => item._id)
        .indexOf(action.payload._id);
      ~removeIndex && state.membership.invites.splice(removeIndex, 1);
    },
    setNewMessage(state, action) {
      console.log("SETTING NEW MESAGE", action.payload);
      const { roomID } = action.payload;
      const roomToUpdate = state.nspData.roomsData.find(
        (r) => r._id === roomID
      );
      roomToUpdate.messages.push(action.payload);
    },
    setAcceptInvite(state, action) {
      const { roomID, user } = action.payload;
      const roomToUpdate = state.nspData.roomsData.find(
        (r) => r._id === roomID.roomID
      );
      roomToUpdate.invited.splice(roomToUpdate.invited.indexOf(user.guid), 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoomData.pending, (state) => {
        state.statusMap.fetchingAllRoomData = "loading";
      })
      .addCase(fetchAllRoomData.fulfilled, (state, action) => {
        state.statusMap.fetchingAllRoomData = "succeeded";
        state.nspData = action.payload;
      })
      .addCase(fetchAllRoomData.rejected, (state, action) => {
        state.statusMap.fetchingAllRoomData = "failed";
        state.errorMap.fetchingRoomData = action.error.message;
      })
      .addCase(fetchRoomData.pending, (state) => {
        state.statusMap.fetchingRoomData = "loading";
      })
      .addCase(fetchRoomData.fulfilled, (state, action) => {
        state.statusMap.fetchingRoomData = "succeeded";
        state.nspData.roomsData.push(action.payload);
      })
      .addCase(fetchRoomData.rejected, (state, action) => {
        state.statusMap.fetchingRoomData = "failed";
        state.errorMap.fetchingRoomData = action.error.message;
      });
  },
});

const { actions, reducer } = socketSlice;
export const {
  socketConnectionSuccess,
  setOnlineMembers,
  setUserInvites,
  setUserRooms,
  setNewRoom,
  setNspData,
  setNewMessage,
  setReceivedNewInvite,
  setAcceptInvite,
} = actions;
export default reducer;

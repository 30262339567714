import "react-native-gesture-handler";
import React from "react";
import { registerRootComponent } from "expo";
import { LogBox, StatusBar, AppRegistry, View, Text } from "react-native";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { NavigationContainer } from "@react-navigation/native";
import { Provider as PaperProvider } from "react-native-paper";
import App from "./src/App.js";
import AuthProvider from "./src/Context/AuthContext";
import StoreProvider from "./src/Context/StoreContext";
import UserProvider from "./src/Context/UserContext";
import SocketProvider from "./src/Context/SocketContext";
import { CustomDarkTheme, CustomDefaultTheme } from "./src/styles/CustomTheme";
import "expo-asset";
import store from "./src/state/state";
import { Provider } from "react-redux";

function Index() {
  // if (typeof LogBox === "object") LogBox.ignoreAllLogs();

  const [isDarkTheme, setDarkTheme] = React.useState(true);
  const theme = isDarkTheme ? CustomDefaultTheme : CustomDarkTheme;

  function toggleTheme() {
    setDarkTheme(!isDarkTheme);
  }

  return (
    <Provider store={store}>
      <AuthProvider>
        <StoreProvider toggleTheme={toggleTheme}>
          <UserProvider>
            <SocketProvider>
              <PaperProvider theme={theme}>
                <NavigationContainer theme={theme}>
                  <StatusBar
                    barStyle={isDarkTheme ? "dark-content" : "light-content"}
                  />
                  <ActionSheetProvider>
                    <App />
                  </ActionSheetProvider>
                </NavigationContainer>
              </PaperProvider>
            </SocketProvider>
          </UserProvider>
        </StoreProvider>
      </AuthProvider>
    </Provider>
  );
}

export default registerRootComponent(Index);

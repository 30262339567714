import React, { useState } from "react";
import { StyleSheet, View, Text, FlatList } from "react-native";
import {
  useTheme,
  Appbar,
  Avatar,
  Button,
  Drawer,
  Card,
  Headline,
  Paragraph,
  Switch,
  TouchableRipple,
  Portal,
  TextInput,
  Modal,
  List,
} from "react-native-paper";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import { AntDesign } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useStore } from "../../Context/StoreContext";
import { useUser } from "../../Context/UserContext";
import { useAuth } from "../../Context/AuthContext";
import { useSocket } from "../../Context/SocketContext";
import ModalForm from "../Prebuilt/ModalForm";
import { signOut } from "../../state/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllRoomData,
  selectHowManyOnlineMembers,
  selectUserData,
  selectRoomInvites,
  selectCurrentNamespaceName,
  selectRoomIds,
  selectUserName
} from "../../state/selectors";
import { sendNspInvite, acceptInvite } from "../../state/socket/socket";

export default function AppDrawerContent({ navigation }) {
  const paperTheme = useTheme();
  const [storeState, storeAction] = useStore();
  const [socketState, socketAction] = useSocket();
  const toggleTheme = () => storeAction.toggleTheme();
  const [openForm, setOpenForm] = useState(false);
  const [userToInvite, setUserToInvite] = useState("");
  const [userList, setUserList] = useState([]);
  const dispatch = useDispatch();
  const namespaceName = useSelector(selectCurrentNamespaceName);
  const onlineMembers = useSelector(selectHowManyOnlineMembers);
  const user = useSelector(selectUserData);
  const roomsData = useSelector(selectAllRoomData);
  const roomInvites = useSelector(selectRoomInvites);
  const roomIDs = useSelector(selectRoomIds);
  const userName = useSelector(selectUserName);

  const [filteredRoomData, setFilteredRoomData] = useState();
  const handleSignOut = () => {
    dispatch(signOut());
  };

  React.useEffect(() => {
    storeAction.setStoredTheme(paperTheme.dark);
  }, [paperTheme.dark]);

  React.useEffect(() => {
    if (roomsData) filterRooms();
  }, [roomsData, roomInvites]);

  const handleAddUserToInvite = (user) => {
    setUserList((prev) => [...prev, user]);
    setUserToInvite("");
  };

  const handleSendNspInvite = () => {
    const data = {
      usernames: userList,
    };
    dispatch(sendNspInvite(data));
    setUserToInvite("");
    setUserList("");
    setOpenForm(false);
  };

  const handleAcceptRoomInvite = (invite) => {
    dispatch(acceptInvite(invite, user));
    navigation.navigate("ChannelChatScreen", { roomID: invite, locked: false });
  };

  const handleDismissModalForm = () => {
    setUserToInvite("");
    setUserList("");
    setOpenForm(false);
  };

  // this doesnt need to be here it does nothing but im focused on other stuff
  const filterRooms = () => {
    setFilteredRoomData(roomsData);
  };

  return (
    <View
      style={{ flex: 1, backgroundColor: paperTheme.background, marginTop: 15 }}
    >
      <Portal>
        <Modal
          visible={openForm}
          onDismiss={() => handleDismissModalForm()}
          contentContainerStyle={{ padding: 10, backgroundColor: "white" }}
        >
          <Headline style={{ padding: 10, margin: 5 }}>
            Invite member to group
          </Headline>
          <TextInput
            value={userToInvite}
            onChangeText={(t) => setUserToInvite(t)}
            style={{ margin: 10 }}
            autoCapitalize="none"
          />
          <Paragraph>Currently Inviting: </Paragraph>
          <FlatList
            data={userList}
            keyExtractor={(_, index) => index.toString()}
            renderItem={({ item }) => {
              return <Paragraph>{item}</Paragraph>;
            }}
          />
          <Button onPress={() => handleAddUserToInvite(userToInvite)}>
            Add User To Invite List
          </Button>
          <Button mode="contained" onPress={() => handleSendNspInvite()}>
            Invite Users
          </Button>
        </Modal>
      </Portal>
      <DrawerContentScrollView>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {/* <View style={{ marginLeft:
           10 }}>
            <Avatar.Image source={{ uri: userState.avatar }} size={40} />
          </View> */}
          <View style={{ marginLeft: 10 }}>
            <Headline
              style={{
                color: paperTheme.dark ? "#fff" : "#000",
              }}
            >
              {namespaceName}
            </Headline>
            <Paragraph
              style={{
                color: paperTheme.dark ? "#fff" : "#000",
              }}
            >
              {userName}
            </Paragraph>
            <Text style={{ color: paperTheme.dark ? "#fff" : "#000" }}>
              Online: {onlineMembers}
            </Text>
            <Button onPress={() => setOpenForm(true)}>
              Invite To {namespaceName}
            </Button>
          </View>
        </View>
        <Drawer.Section style={{ paddingTop: 10 }} />

        {/* <Drawer.Section>
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="wechat"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Blindly Chat"
            onPress={() => navigation.navigate("Chat")}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="message1"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Direct Message"
            onPress={() => navigation.navigate("DirectMessages")}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="menuunfold"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Threads"
            onPress={() => navigation.navigate("Threads")}
            drawerLabel={{ color: paperTheme.text }}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="profile"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Profile"
            onPress={() => navigation.navigate("Profile")}
            drawerLabel={{ color: paperTheme.text }}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="setting"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Settings"
            onPress={() => navigation.navigate("Settings")}
            drawerLabel={{ color: paperTheme.text }}
          />
          <Drawer.Item
            icon={({ color, size }) => (
              <AntDesign
                name="contacts"
                color={paperTheme.dark ? "#fff" : "#000"}
                size={size}
              />
            )}
            label="Support"
            onPress={() => navigation.navigate("Support")}
            drawerLabel={{ color: paperTheme.text }}
          />
        </Drawer.Section> */}

        {/* We need to map over all rooms and put them here */}
        <Drawer.Section title="Channels">
          <Drawer.Item
            label=" + Create New Channel"
            onPress={() => navigation.navigate("CreateChannelForm")}
            style={{ borderBottomColor: "black", borderWidth: 1, margin: 2 }}
          />
          {filteredRoomData &&
            filteredRoomData.map((room) => {
              if (!room._id || !room.messages) {
                return (
                  <Drawer.Item
                    key={room._id}
                    icon="lock"
                    label={room}
                    onPress={() =>
                      navigation.navigate("ChannelChatScreen", {
                        // sending a 0 to tell the chat room that user does not have access
                        roomID: 0,
                        roomName: room,
                        locked: true,
                      })
                    }
                    // TODO -> change this to dispatch the accept ninvite event
                    drawerLabel={{ color: paperTheme.text }}
                  />
                );
              } else if (room.invited.includes(user.guid)) {
                return (
                  <Drawer.Item
                    key={room._id}
                    icon="star"
                    label={room.name}
                    onPress={() => handleAcceptRoomInvite(room._id)}
                    drawerLabel={{ color: paperTheme.text }}
                  />
                );
              } else {
                return (
                  <Drawer.Item
                    key={room._id}
                    testID={room.name}
                    label={room.name}
                    id
                    icon="account-multiple-outline"
                    onPress={() =>
                      navigation.navigate("ChannelChatScreen", {
                        roomID: room._id,
                        locked: false,
                      })
                    }
                    drawerLabel={{ color: paperTheme.text }}
                  />
                );
              }
            })}
        </Drawer.Section>

        <Drawer.Section title="Options">
          <TouchableRipple onPress={toggleTheme} style={{ marginBottom: 5 }}>
            <View style={styles.options}>
              <Text style={{ color: paperTheme.dark ? "#fff" : "#000" }}>
                Dark Theme
              </Text>
              <View pointerEvents="none">
                <Switch value={paperTheme.dark} />
              </View>
            </View>
          </TouchableRipple>
        </Drawer.Section>
      </DrawerContentScrollView>

      <Drawer.Section style={styles.bottomDrawerSection}>
        <Drawer.Item
          icon={({ color, size }) => (
            <AntDesign
              name="leftcircleo"
              color={paperTheme.dark ? "#fff" : "#000"}
              size={size}
            />
          )}
          label="Sign Out"
          onPress={() => handleSignOut()}
        />
      </Drawer.Section>
    </View>
  );
}

const styles = StyleSheet.create({
  bottomDrawerSection: {
    paddingTop: 5,
    marginBottom: 15,
    borderTopColor: "#f4f4f4",
    borderTopWidth: 1,
  },
  options: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../services/auth/AuthRoutes";
import { createNewNsp } from "../../services/http/chat/createNewNsp";
import BlindlyGroupsApi from "../../services/http/config";
import { openSocket } from "../socket/socket";

// TODO -> delete namespace

export const initialState = {
  namespace: undefined,
  statusMap: {
    createNamespace: "idle",
  },
  errorMap: {
    createNamespace: undefined,
  },
};

export const createNamespace = createAsyncThunk(
"namespace/createNewNamespace",
  async ({ name }, thunkApi) => {
    const response = await createNewNsp(name);
    const { nsp } = response;
    const accessToken = await getAccessToken();

    if (!nsp) {
      thunkApi.rejectWithValue({
        type: "NAMESPACE_FAIL",
        reason: "Failed to create namesapce",
      });
    }
    thunkApi.dispatch(openSocket(BlindlyGroupsApi.socket, nsp, accessToken));
    return nsp;
  }
);

const namespaceSlice = createSlice({
  name: "namespace",
  initialState,
  reducers: {
    setNamespace(state, action) {
      state.namespace = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNamespace.pending, (state) => {
        state.statusMap.createNamespace = "loading";
      })
      .addCase(createNamespace.fulfilled, (state, action) => {
        state.statusMap.createNamespace = "succeeded";
        state.namespace = action.payload;
      })
      .addCase(createNamespace.rejected, (state, action) => {
        state.statusMap.createNamespace = "failed";
        state.errorMap.createNamespace = action.error.message;
      });
  },
});

const { actions, reducer } = namespaceSlice;
export const { setNamespace } = actions;
export default reducer;

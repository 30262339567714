export const selectIsSignedId = (state) =>
  !!state.user?.user && !!state.user?.user.guid;
export const selectUserMembership = (state) => state.user?.membership;
export const selectNspIds = (state) => {
  const ids = state.user?.membership.map((n) => n.id);
  return ids;
};
export const selectNspNames = (state) => {
  const names = state.user?.membership.map((n) => n.name);
  return names;
};
export const selectUserData = (state) => state.user.user;
export const selectUserName = (state) => state.user?.userName;
export const selectCurrentNamespace = (state) => state.socket?.namespace;
export const selectCurrentNamespaceName = (state) => state.socket.nspData.name;
export const selectState = (state) => state;
export const selectIsConnected = (state) => state.socket.connected;

export const selectHowManyOnlineMembers = (state) =>
  state.socket.onlineMembers.length;
export const selectRoomsIds = (state) => {
  return state.socket.nspData?.roomIds;
};
export const selectAllRoomData = (state) => {
  return state.socket.nspData.roomsData;
};
export const findRoomById = (id) => (state) => {
  return state.socket.nspData.roomsData.find((r) => r._id === id);
};
export const selectRoomInvites = (state) => {
  const invites = state.user?.membership.invites.filter(
    (i) => i.inviteType === "room"
  );
  return invites;
};

export const selectNewRoomInvites = (state) => state.socket?.invites;
export const selectRoomIds = (state) => state.socket.nspData?.roomIDs;

export const selectIsAppLoading = (state) =>
  state.socket.statusMap.fetchingAllRoomData;

import { getAccessToken, getRefreshToken, clearTokens } from './AuthRoutes';
import { setTokens } from './AuthRoutes';

// USE AUTHCONTEXT DISPATCH FUNCTION, GRAB ACCESSTOKEN, SEND TO AUTHCONTEXT STORAGE
export const restoreAccessToken = async (dispatch) => {
  const restoreToken = async () => {    
    try {
      let accessToken = await getAccessToken();
      let refreshToken = await getRefreshToken();

      if (accessToken && refreshToken) {
        await setTokens(accessToken, refreshToken);
        dispatch({ type: 'SIGN_IN', val: accessToken })
      }
    } catch (err) {
      console.error("Error restoring access token TEST", err);
    };
  };
  restoreToken()
};

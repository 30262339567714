import React, {useState} from 'react';
import {
  View,
  Text,
  SafeAreaView,
  TextInput,
  SectionList,
  Button,
} from 'react-native';
import { ChannelListItem } from '../ChannelListItem/ChannelListItem';
import Heartbeat from '../Heartbeat/Heartbeat';

import { createChatRoom } from '../../services/http/chat/createChatRoom';
import { addUserMemberShip } from '../../services/http/chat/addUserMembership';
import { inviteToRoom } from '../../services/http/chat/inviteToRoom';

export const ChannelList = ({changeChannel, channels, user, styles, userMemberships}) => {
  const [activeChannelId, setActiveChannelId] = useState(null);
  const renderChannelRow = (channel, isUnread, sectionTitle) => {
      return (
        <ChannelListItem
          userMemberships={userMemberships}
          changeChannel={changeChannel}
          setActiveChannelId={setActiveChannelId}
          activeChannelId={activeChannelId}
          styles={styles}
          sectionTitle={sectionTitle}
          channel={channel}
          key={channel.name}
        />
      );
    }


  return (
    <SafeAreaView>
      <View style={styles.channelContainer}>
        <View style={styles.headerContainer}>
          <Heartbeat styles={styles}/>
          <TextInput
            style={styles.inputSearchBox}
            placeholderTextColor="grey"
            placeholder="Jump to"
          />
        </View>

        <SectionList
          style={styles.sectionList}
          sections={channels}
          keyExtractor={(item, index) => item.id + index}
          renderItem={({item, section}) => {
            return renderChannelRow(item, section.id === 'unread', section.title);
          }}
          renderSectionHeader={({section: {title}}) => {
            const addChatRoom  = async () => {
              const newChatRoom = await createChatRoom([]);
              const newRoomID = newChatRoom.room._id;
              await addUserMemberShip(newRoomID);
              await inviteToRoom([], newRoomID);
            }
            return (
            <View style={styles.channelListContainer}>
              <View style={styles.groupTitleContainer}>
                <Text style={styles.groupTitle}>{title}</Text>
              </View>
              <View style={styles.addChannelButtonContainer}>
                <Button title="+" style={styles.addChannelButton} onPress={() => addChatRoom()}/>
              </View>
            </View>
          )}}
        />
      </View>
    </SafeAreaView>
  );
};
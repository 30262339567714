import React from "react";
import {
  ScrollView,
  Alert,
  StyleSheet,
  View,
  Image,
  Animated,
  SafeAreaView,
} from "react-native";
import { useTheme, Button, TextInput } from "react-native-paper";
import Center from "../components/Prebuilt/Center";
import AlertView from "../components/Prebuilt/AlertView";
import { validateSignIn } from "../validation/validationSchemas";
import { getAccessToken, clearTokens } from "../services/auth/AuthRoutes";
import { useAuth } from "../Context/AuthContext";
import { loginUser } from "../services/http/blindlyGroups/AuthDao";
import { setTokens } from "../services/auth/AuthRoutes";
import axios from "axios";
import BlindlyGroupsApi from "../services/http/config";
import { loginQuarium } from "../state/slices/userSlice";
import { useDispatch } from "react-redux";
import Icon from "../Assets/demoIcon.png";

const SERVICE = BlindlyGroupsApi.auth;

export default function LoginScreen(props) {
  const [authState, authAction] = useAuth();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [alert, setAlert] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const paperTheme = useTheme();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const registeredEmail = props.route.params?.email;
    if (registeredEmail) {
      setEmail(props.route.params?.email);
    }
  }, [props.route.params]);
  const handleSignIn = async () => {
    setAlert(null);
    validateSignIn(
      email.trim(),
      password,
      (error) => {
        setAlert(error);
        setSuccess(false);
      },
      (success) => {
        setSuccess(true);
        const doLogin = async () => {
          dispatch(loginQuarium({ email: email.trim(), password }));
        };
        doLogin();
      }
    );
  };

  return (
    <ScrollView>
      <SafeAreaView style={styles.container}>
        <Image style={styles.logo} source={Icon} />

        {/* <Text
        style={[
          styles.inputTitle,
          { color: paperTheme.dark ? "#fff" : "#000" },
        ]}
      >
        Sign In with your Email
      </Text> */}

        <AlertView title={alert} onClose={() => setAlert(null)} />

        <View style={styles.inputContainer}>
          <TextInput
            testID="loginEmail"
            name="email"
            label="Email"
            value={email}
            style={styles.textInput}
            onChangeText={setEmail}
            autoCapitalize={"none"}
          />

          <TextInput
            testID="loginPassword"
            label="Password"
            value={password}
            style={styles.textInput}
            onChangeText={setPassword}
            secureTextEntry={true}
            autoCapitalize={"none"}
          />
        </View>

        <Button
          testID="loginBtn"
          title="Sign In"
          mode="contained"
          style={styles.signInButton}
          loading={success}
          onPress={handleSignIn}
          disabled={success}
        >
          Sign In
        </Button>

        <Button
          testID="registerBtn"
          title="Register"
          mode="outlined"
          style={{
            ...styles.registerButton,
          }}
          onPress={() => props.navigation.push("Sign Up")}
        >
          New User? Register Here
        </Button>
      </SafeAreaView>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    margin: 10,
  },
  inputTitle: {
    fontWeight: "600",
    margin: 10,
  },
  textInput: {
    margin: 10,
  },
  signInButton: {
    margin: 10,
    marginTop: 15,
  },
  registerButton: {
    margin: 10,
  },
  logo: {
    margin: 10,
    marginBottom: 30,
    height: "30%",
    width: "40%",
    alignSelf: "center",
  },
  inputContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
});
